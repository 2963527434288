<template>
  <div class="enSensors-realtime-view">
    <div class="child-page-title">
      <h3>实时环境监测</h3>
    </div>
    <div v-if="hasVal(realtime)" class="realtime-context">
      <div class="top-context">
        <div>
          <img :src="realtime.pM25 >= threshold.pm25 ? kjgqYjSrc : kjgqSrc">
          <div>
            PM2.5
            <dv-digital-flop :config="pm25Config" class="dv-digital-flop" />
          </div>
        </div>
        <div>
          <img :src="realtime.pM10 >= threshold.pm10 ? kjgqYjSrc : kjgqSrc">
          <div>
            PM10
            <dv-digital-flop :config="pm10Config" class="dv-digital-flop" />
          </div>
        </div>
        <div>
          <img :src="realtime.tsp >= threshold.tsp ? kjgqYjSrc : kjgqSrc">
          <div>
            TSP
            <dv-digital-flop :config="tspConfig" class="dv-digital-flop" />
          </div>
        </div>
        <div>
          <img :src="realtime.noise >= threshold.noise ? kjgqYjSrc : kjgqSrc">
          <div>
            噪音
            <dv-digital-flop :config="noiseConfig" class="dv-digital-flop" />
          </div>
        </div>
      </div>
      <div class="bottom-context">
        <div>
          <div>
            <img src="../../../assets/image/temperature.png">
          </div>
          <div class="text">
            温度
            <dv-digital-flop
              :config="temperatureConfig"
              class="dv-digital-flop"
            />
          </div>
        </div>
        <div>
          <div>
            <img src="../../../assets/image/humidity.png">
          </div>
          <div class="text">
            湿度
            <dv-digital-flop :config="humidityConfig" class="dv-digital-flop" />
          </div>
        </div>
        <div>
          <div>
            <img src="../../../assets/image/atmospherePressure.png">
          </div>
          <div class="text">
            大气压
            <dv-digital-flop
              :config="atmospherePressureConfig"
              class="dv-digital-flop"
            />
          </div>
        </div>
      </div>
      <div class="bottom-context">
        <div>
          <div>
            <img src="../../../assets/image/windDirection.png">
          </div>
          <div class="text">
            风速
            <dv-digital-flop
              :config="windSpeedConfig"
              class="dv-digital-flop"
            />
          </div>
        </div>
        <div>
          <div>
            <img src="../../../assets/image/windSpeed.png">
          </div>
          <div class="text">
            风向
            <div class="dv-digital-flop">{{ getWindDirectionStr(realtime.windDirection) }}</div>
          </div>
        </div>
      </div>
      <div class="bottom-device-txt">
        <div>
          <img
            src="../../../assets/image/ycy.png"
            style="border-radius: 10px"
          >{{ realtime.name }}
        </div>
        <div>
          <img src="../../../assets/image/time.png">{{
            parseTime(realtime.time)
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { hasVal, parseTime } from '@/utils/index'
export default {
  name: 'EnSensorsRealTimeView',
  props: {
    realtime: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      kjgqSrc: require('../../../assets/image/kjgq.png'),
      kjgqYjSrc: require('../../../assets/image/kjgq4.png'),
      style: {
        fill: '#029FEE'
      },
      styleyj: {
        fill: '#E77903'
      },
      pm25Config: null,
      pm10Config: null,
      tspConfig: null,
      noiseConfig: null,
      temperatureConfig: null,
      atmospherePressureConfig: null,
      humidityConfig: null,
      windSpeedConfig: null,
      threshold: {
        pm25: 50,
        pm10: 80,
        tsp: 300,
        noise: 70,
        humidity: 150,
        temperature: 38,
        windSpeed: 13.8
      }
    }
  },
  watch: {
    realtime(val) {
      this.removeEnSensorsRealTime()
    }
  },
  mounted() {
    this.removeEnSensorsRealTime()
  },
  methods: {
    removeEnSensorsRealTime() {
      const _that = this
      if (hasVal(_that.realtime)) {
        _that.getConfig()
      }
    },
    getConfig() {
      const _that = this
      _that.pm25Config = {
        number: [Number(_that.realtime.pM25)],
        style:
          _that.realtime.pM25 >= _that.threshold.pm25
            ? _that.styleyj
            : _that.style
      }
      _that.pm10Config = {
        number: [Number(_that.realtime.pM10)],
        style:
          _that.realtime.pM10 >= _that.threshold.pm10
            ? _that.styleyj
            : _that.style
      }
      _that.tspConfig = {
        number: [Number(_that.realtime.tsp)],
        style:
          _that.realtime.tsp >= _that.threshold.tsp
            ? _that.styleyj
            : _that.style
      }
      _that.noiseConfig = {
        number: [Number(_that.realtime.noise)],
        style:
          _that.realtime.noise >= _that.threshold.noise
            ? _that.styleyj
            : _that.style
      }
      _that.temperatureConfig = {
        number: [Number(_that.realtime.temperature)],
        style:
          _that.realtime.temperature >= _that.threshold.temperature
            ? _that.styleyj
            : _that.style,
        textAlign: 'left',
        content: '{nt}°C'
      }
      _that.atmospherePressureConfig = {
        number: [Number(_that.realtime.atmospherePressure)],
        style: this.style,
        textAlign: 'left',
        content: '{nt}hpa'
      }
      _that.humidityConfig = {
        number: [Number(_that.realtime.humidity)],
        style:
          _that.realtime.humidity >= _that.threshold.humidity
            ? _that.styleyj
            : _that.style,
        textAlign: 'left',
        content: '{nt}%'
      }
      _that.windSpeedConfig = {
        number: [Number(_that.realtime.windSpeed)],
        style:
          _that.realtime.windSpeed >= _that.threshold.windSpeed
            ? _that.styleyj
            : _that.style,
        textAlign: 'left',
        content: '{nt}m/s'
      }
    },
    getWindDirectionStr(windDirection) {
      switch (windDirection) {
        case 0:
          return '东北偏北'
        case 1:
          return '东北'
        case 2:
          return '东北偏东'
        case 3:
          return '正东'
        case 4:
          return '东南偏东'
        case 5:
          return '东南'
        case 6:
          return '东南偏南'
        case 7:
          return '正南'
        case 8:
          return '西南偏南'
        case 9:
          return '西南'
        case 10:
          return '西南偏西'
        case 11:
          return '正西'
        case 12:
          return '西北偏西'
        case 13:
          return '西北'
        case 14:
          return '西北偏北'
        default:
          return '正北'
      }
    },
    parseTime(val) {
      return parseTime(val, '{y}-{m}-{d} {h}:{i}:{s}')
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.enSensors-realtime-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .realtime-context {
    margin-top: 30px;
    > div {
      display: flex;
      > div {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .top-context {
      width: 96%;
      height: 150px;
      box-sizing: border-box;
      border-radius: 5px;
      margin-left: 15px;
      margin-top: 35px;
      > div {
        flex: 1;
        text-align: center;
        min-width: 25%;
        > div {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          position: absolute;
          top: 40px;
          left: 65px;
          .dv-digital-flop {
            height: 30px;
          }
        }
        position: relative;
        display: inline-block;
        > img {
          width: 140px;
          height: 140px;
        }
      }
    }
    .bottom-context {
      width: 96%;
      height: 65px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      margin: 0 auto;
      margin-top: 6%;
      align-items: center;
      > div {
        display: flex;
        width: 33%;
        border-right: 1px dashed #0e77ca9e;
        > div {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 36px;
          flex: 1;
          text-align: center;
          > img {
            width: 70px;
            height: 70px;
          }
        }
        .text {
          text-align: left !important;
          .dv-digital-flop {
            height: 30px;
          }
        }
      }
    }
    .bottom-device-txt {
      margin-top: 30px;
      margin-right: 20px;
      display: block;
      float: right;
      > div {
        > img {
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>

