<template>
  <div class="home">
    <child-page-head
      ref="pageHead"
      :title="title"
      :timer-show="true"
      :time-num="timeNum"
    />
    <div class="home-top-main" />
    <!-- 方块组件 -->
    <drawer-envSensors>
      <!-- 左侧内容-上 -->
      <div slot="leftDrawerTopContent">
        <left-drawer-content
          id="nvSensors-line-pm25Ug-stat"
          title="PM2.5"
          :val-options="pm25Options"
        />
      </div>
      <!-- 左侧内容-中 -->
      <div slot="leftDrawerMiddleContent">
        <left-drawer-content
          id="nvSensors-line-pm10Ug-stat"
          title="PM10"
          :val-options="pm10Options"
        />
      </div>
      <!-- 左侧内容-下 -->
      <div slot="leftDrawerBottomContent">
        <left-drawer-content
          id="nvSensors-line-tspUg-stat"
          title="颗粒物"
          :val-options="tspOpions"
        />
      </div>
      <!-- 中间部分-上 -->
      <div slot="MiddleDrawerTopContent">
        <middle-drawer-top-content :realtime="realtime" />
      </div>
      <!-- 中间部分-中 -->
      <div slot="MiddleDrawerMiddleContent">
        <weather-log-drawer-content />
      </div>
      <!-- 右侧内容-上 -->
      <div slot="rightDrawerTopContent">
        <right-drawer-top-content :sn="sn" />
      </div>
      <!-- 右侧内容-中 -->
      <div slot="rightDrawerMiddleContent">
        <right-drawer-middle-content :sn="sn" />
      </div>
      <!-- 右侧内容-下 -->
      <div slot="rightDrawerBottomContent">
        <alarm-log-drawer-content type="ENVSENSOR" />
      </div>
    </drawer-envSensors>
  </div>
</template>
<script>
import { getAllEnvSensorRealData, get7DaysPMStat } from '@/api/envSensors'
import childPageHead from '@/components/child-page-head'
import drawerEnvSensors from '@/components/drawer-envSensors'
import weatherLogDrawerContent from '@/views/common/weather-log-drawer-content.vue'
import middleDrawerTopContent from './components/middle-drawer-top-content.vue'
import alarmLogDrawerContent from '@/views/common/alarm-log-drawer-content.vue'
import rightDrawerTopContent from './components/right-drawer-top-content.vue'
import rightDrawerMiddleContent from './components/right-drawer-middle-content.vue'
import leftDrawerContent from './components/left-drawer-content.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'PersonnelHome',
  components: {
    drawerEnvSensors,
    childPageHead,
    middleDrawerTopContent,
    weatherLogDrawerContent,
    alarmLogDrawerContent,
    rightDrawerTopContent,
    rightDrawerMiddleContent,
    leftDrawerContent
  },
  data() {
    return {
      title: '环境监测',
      realtime: null,
      enSensorsRealTimeOptions: [],
      envSensorsGrainRealTimeOptions: [],
      index: 0,
      sn: null,
      pm25Options: [],
      pm10Options: [],
      tspOpions: [],
      timeNum: null
    }
  },
  mounted() {
    const _that = this
    _that.removeEnSensorsRealTime(() => {
      this.$nextTick(() => {
        _that.remove7DaysEnvSensorsGrainRealTime(() => {
          _that.handleData(_that)
          _that.switchData()
        })
      })
    })
  },
  methods: {
    removeEnSensorsRealTime(callback) {
      const _that = this
      _that.enSensorsRealTimeOptions = []
      getAllEnvSensorRealData().then((res) => {
        const { data } = res
        if (hasVal(data.items)) {
          _that.enSensorsRealTimeOptions = data.items
        }
        if (callback && typeof callback === 'function') {
          callback(_that.enSensorsRealTimeOptions)
        }
      })
    },
    remove7DaysEnvSensorsGrainRealTime(callback) {
      const _that = this
      _that.envSensorsGrainRealTimeOptions = []
      get7DaysPMStat().then((res) => {
        const { data } = res
        if (hasVal(data.items)) {
          _that.envSensorsGrainRealTimeOptions = data.items
        }
        if (callback && typeof callback === 'function') {
          callback(_that.envSensorsGrainRealTimeOptions)
        }
      })
    },
    /**
     * 时间计时器
     */
    switchData() {
      const _that = this
      setInterval(() => {
        _that.handleData(_that)
      }, 20000)
      this.timeNum = 20
    },
    handleData(_that) {
      if (!hasVal(_that.enSensorsRealTimeOptions)) {
        return
      }
      _that.pm25Options = []
      _that.pm10Options = []
      _that.tspOpions = []
      _that.realtime = _that.enSensorsRealTimeOptions[_that.index]
      _that.sn = _that.realtime.sn
      if (hasVal(_that.envSensorsGrainRealTimeOptions)) {
        const _stat = _that.envSensorsGrainRealTimeOptions.filter(
          (item) => item.sn === _that.sn
        )
        if (hasVal(_stat)) {
          if (hasVal(_stat[0].pM25)) {
            _that.pm25Options.push({
              name: 'PM25',
              data: _stat[0].pM25,
              isAllData: false
            })
          }
          if (hasVal(_stat[0].pM10)) {
            _that.pm10Options.push({
              name: 'PM10',
              data: _stat[0].pM10,
              isAllData: false
            })
          }
          if (hasVal(_stat[0].tsp)) {
            _that.tspOpions.push({
              name: '颗粒物',
              data: _stat[0].tsp,
              isAllData: false
            })
          }
        }
      }
      _that.index =
        _that.enSensorsRealTimeOptions.length - 1 <= _that.index
          ? 0
          : _that.index + 1
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  background: url(../../assets/image/body-bg.jpg) no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  zoom: 1;
  &-top-main {
    padding: 0 13px;
    display: flex;
    justify-content: space-between;
    height: "calc(100vh - 102px)";
  }
}
</style>
