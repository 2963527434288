<template>
  <!-- 方块组件 -->
  <div class="drawer-box-block">
    <!-- 左侧方块 -->
    <div class="drawer-box-left">
      <!-- 左侧上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-top"
      >
        <slot name="leftDrawerTopContent" />
      </dv-border-box-7>
      <!-- 左侧中半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-middle"
      >
        <slot name="leftDrawerMiddleContent" />
      </dv-border-box-7>
      <!-- 左侧下半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-left-bottom"
      >
        <slot name="leftDrawerBottomContent" />
      </dv-border-box-7>
    </div>
    <!--中间方块-->
    <div class="drawer-box-middle">
      <!-- 中间上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-middle-top"
      >
        <slot name="MiddleDrawerTopContent" />
      </dv-border-box-7>
      <!-- 中间中部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-middle-middle"
      >
        <slot name="MiddleDrawerMiddleContent" />
      </dv-border-box-7>
    </div>
    <!-- 右侧方块 -->
    <div class="drawer-box-right">
      <!-- 右侧上半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-top"
      >
        <slot name="rightDrawerTopContent" />
      </dv-border-box-7>
      <!-- 右侧中间部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-middle"
      >
        <slot name="rightDrawerMiddleContent" />
      </dv-border-box-7>
      <!-- 右侧下半部分 -->
      <dv-border-box-7
        :color="['#10568e', 'rgb(124, 231, 253)']"
        class="drawer-box-right-bottom"
      >
        <slot name="rightDrawerBottomContent" />
      </dv-border-box-7>
    </div>
  </div>
</template>
<script>
export default {
  name: "DrawerEnvSensors",
  props: {
    title: {
      type: String,
      default: "形象工程",
    },
  },
};
</script>
<style lang="scss" scoped>
.drawer-box-block {
  // 左侧方块样式
  .drawer-box-left {
    height: 924px;
    width: 519px;
    position: fixed;
    top: 125px;
    z-index: 1;
    opacity: 0.92;
    left: 15px;
    .drawer-box-left-top {
      width: 100%;
      height: 300px;
      border: 0px;
      background: rgba(100, 116, 211, 0.04);
      opacity: 0.8;
    }
    .drawer-box-left-middle {
      margin-top: 12px;
      width: 100%;
      height: 300px;
      border: 0px;
      background: rgba(100, 116, 211, 0.04);
      opacity: 0.8;
    }
    .drawer-box-left-bottom {
      margin-top: 12px;
      width: 100%;
      height: 300px;
      border: 0px;
      background: rgba(100, 116, 211, 0.04);
      opacity: 0.8;
    }
  }
  .drawer-box-middle {
    width: 825px;
    height: 924px;
    position: fixed;
    top: 125px;
    z-index: 1;
    opacity: 0.92;
    left: 547px;
    .drawer-box-middle-top {
      width: 100%;
      height: 614px;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
    .drawer-box-middle-middle {
      width: 825px;
      height: 300px;
      margin-top: 12px;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
  }

  // 右侧方块样式
  .drawer-box-right {
    height: 924px;
    width: 519px;
    position: fixed;
    top: 125px;
    z-index: 1;
    opacity: 0.92;
    right: 15px;
    .drawer-box-right-top {
      width: 100%;
      height: 300px;
      background: rgba(100, 116, 211, 0.04);
      border: 0px;
      opacity: 0.8;
    }
    .drawer-box-right-middle {
      margin-top: 12px;
      width: 100%;
      height: 300px;
      border: 0px;
      opacity: 0.8;
      display: flex;
    }
    .drawer-box-right-bottom {
      margin-top: 12px;
      background: rgba(100, 116, 211, 0.04);
      width: 100%;
      height: 300px;
      border: 0px;
      opacity: 0.8;
    }
  }
}
</style>
