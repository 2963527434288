import request from '@/utils/request'

/** *
 * 获取所有环境监测设备的实时数据
 */
export function getAllEnvSensorRealData() {
  return request({
    url: '/Device/GetAllEnvSensorRealData',
    method: 'GET'
  })
}

/** *
 * 获取噪音达标统计数据
 */
export function getNoiseCriteriaStat() {
  return request({
    hostType: 'BILOG',
    url: '/EnvSensors/GetNoiseCriteriaStat',
    method: 'GET'
  })
}

/** *
 * 近24小时环境监测仪的各项指标数据
 */
export function get24HoursStat() {
  return request({
    hostType: 'BILOG',
    url: '/EnvSensors/Get24HoursStat',
    method: 'GET'
  })
}

/** *
 * 近七天扬尘变化数据
 */
export function get7DaysPMStat() {
  return request({
    hostType: 'BILOG',
    url: '/EnvSensors/Get7DaysPMStat',
    method: 'GET'
  })
}

/** *
 * 近七天噪音变化数据
 */
export function get7DaysNoiseStat() {
  return request({
    hostType: 'BILOG',
    url: '/EnvSensors/Get7DaysNoiseStat',
    method: 'GET'
  })
}

