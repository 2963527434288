<template>
  <div class="envSensors-noise-view">
    <div class="child-page-title">
      <h3>近7天噪音变化趋势</h3>
    </div>
    <div v-if="hasVal(envSensorsNoiseStat)" class="stat-charts-context">
      <line-chart
        id="envSensors-line-noise-stat"
        :val-options="envSensorsNoiseStat"
        title=""
        unit="dB"
        y-axis-title="噪音变化指数(dB)"
        serie-name="变化指标"
      />
    </div>
    <div v-else class="stat-charts-context empty">暂无近7天噪音变化趋势信息</div>
  </div>
</template>
<script>
import { get7DaysNoiseStat } from '@/api/envSensors'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'EnvSensorsNoiseLineStat',
  components: { lineChart },
  props: {
    sn: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      envSensorsNoiseStat: [],
      envSensorsNoiseStatAll: []
    }
  },
  watch: {
    sn(val) {
      this.removeEnvSensorsNoiseLine()
    }
  },
  mounted() {
    this.removeEnvSensorsNoiseStat()
  },
  methods: {
    removeEnvSensorsNoiseStat() {
      this.envSensorsNoiseStatAll = []
      get7DaysNoiseStat().then((res) => {
        const { data } = res
        if (hasVal(data.items)) {
          this.envSensorsNoiseStatAll = data.items
          this.removeEnvSensorsNoiseLine()
        }
      })
    },
    removeEnvSensorsNoiseLine() {
      this.envSensorsNoiseStat = []
      if (hasVal(this.sn)) {
        const _stat = this.envSensorsNoiseStatAll.filter(
          (item) => item.sn === this.sn
        )
        if (hasVal(_stat)) {
          if (hasVal(_stat[0].noise)) {
            this.envSensorsNoiseStat.push({
              name: '噪音',
              data: _stat[0].noise,
              isAllData: false
            })
          }
        }
      }
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.envSensors-noise-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-charts-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
