<template>
  <div class="envSensors-7Ug-view">
    <div class="child-page-title">
      <h3>近7天{{ title }}变化趋势</h3>
    </div>
    <div v-if="hasVal(valOptions)" class="stat-charts-context">
      <line-chart
        :id="id"
        :val-options="valOptions"
        title=""
        unit="ug/m3"
        :y-axis-title="title + '变化指数(ug/m3)'"
        serie-name="变化指标"
      />
    </div>
    <div v-else class="stat-charts-context empty">
      暂无近7天{{ title }}变化趋势信息
    </div>
  </div>
</template>
<script>
import { get7DaysPMStat } from '@/api/envSensors'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'EnvSensors7UgLineStat',
  components: { lineChart },
  props: {
    id: {
      type: String,
      default: 'nvSensors-line-stat'
    },
    title: {
      type: String,
      default: 'PM25'
    },
    valOptions: {
      type: Array,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.envSensors-7Ug-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-charts-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
