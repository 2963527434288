<template>
  <div class="envSensors-stat-view">
    <div class="child-page-title">
      <h3>近24小时各指标趋势</h3>
    </div>
    <div v-if="hasVal(envSensors24Stat)" class="stat-charts-context">
      <line-chart
        id="envSensors-line-24-stat"
        :val-options="envSensors24Stat"
        title=""
        unit="ug/m3(dB)"
        y-axis-title="24小时指标指数(ug/m3(dB))"
        serie-name="环境指标"
      />
    </div>
    <div v-else class="stat-charts-context empty">暂无近24小时指标趋势信息</div>
  </div>
</template>
<script>
import { get24HoursStat } from '@/api/envSensors'
import lineChart from '@/components/chart/lineChart.vue'
import { hasVal } from '@/utils/index'
export default {
  name: 'EnvSensors24LineStat',
  components: { lineChart },
  props: {
    sn: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      envSensors24Stat: [],
      envSensors24StatAll: []
    }
  },
  watch: {
    sn(val) {
      this.removeEnvSensors24Line()
    }
  },
  mounted() {
    this.removeEnvSensors24Stat()
  },
  methods: {
    removeEnvSensors24Stat() {
      this.envSensors24StatAll = []
      get24HoursStat().then((res) => {
        const { data } = res
        if (hasVal(data.items)) {
          this.envSensors24StatAll = data.items
          this.removeEnvSensors24Line()
        }
      })
    },
    removeEnvSensors24Line() {
      this.envSensors24Stat = []
      if (hasVal(this.sn)) {
        const _stat = this.envSensors24StatAll.filter(
          (item) => item.sn === this.sn
        )
        if (hasVal(_stat)) {
          if (hasVal(_stat[0].pM25)) {
            this.envSensors24Stat.push({
              name: 'PM25',
              data: _stat[0].pM25,
              isAllData: false
            })
          }
          if (hasVal(_stat[0].pM10)) {
            this.envSensors24Stat.push({
              name: 'PM10',
              data: _stat[0].pM10,
              isAllData: false
            })
          }
          if (hasVal(_stat[0].tsp)) {
            this.envSensors24Stat.push({
              name: '颗粒物',
              data: _stat[0].tsp,
              isAllData: false
            })
          }
          if (hasVal(_stat[0].noise)) {
            this.envSensors24Stat.push({
              name: '噪音',
              data: _stat[0].noise,
              isAllData: false
            })
          }
        }
      }
    },
    hasVal(val) {
      return hasVal(val)
    }
  }
}
</script>
<style lang='scss' scoped>
.envSensors-stat-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stat-charts-context {
    width: 99%;
    padding-left: 0.5%;
  }
}
</style>
